.ontologies {
  padding: 16px;
  &__block {
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    &-title,
    & .title {
      flex-basis: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      &__buttons {
        &-remove {
          color: rgb(255, 77, 79);
        }
        a {
          padding: 0 10px;
        }
      }
    }
    &-item,
    .ontology {
      cursor: pointer;
      flex-basis: 33%;
      padding: 16px;
      margin-right: 16px;
      margin-bottom: 16px;
      //border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: rgb(70, 70, 70);
      color: #fff;
      border-radius: 5px;
      &__name {
        font-weight: bold;
      }
      &__preset {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}

.scenario,
.ontology-filter {
  position: absolute;
  padding: 10px;
  z-index: 999;
  width: 100%;
  .ant-btn-dashed {
    color: rgba(70, 70, 70, 0.5);
  }
}

.duplicate-modal .ant-alert,
.remove-modal .ant-alert {
  margin-bottom: 16px;
}
