.discussion {
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  z-index: 999;
  overflow: hidden;
  transition: width 0.2s ease;
  button {
    width: 60px !important;
    height: 60px !important;
    padding: 12px !important;
  }
}
