@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

@import "ontologies";
@import "legend";
@import "discussion";
@import "history";

#root,
.ant-layout,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-layout-content {
  height: 100%;
}

.ant-layout-header {
  z-index: 1001;
}

.ant-drawer {
  padding-top: 64px;
}

.filter__item {
  margin-bottom: 16px;
}

.vis-network {
  height: 100%;
}

.ant-drawer-footer {
  top: -63px;
  position: relative;
  background-color: #fff;
}

.linked-node {
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
}
