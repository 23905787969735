.legend {
  position: absolute;
  bottom: 20px;
  left: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 999;

  &__button {
    margin: 0 0 5px 5px;
  }
  &__group {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    &-item {
      font-weight: bold;
      font-size: 0.85em;
      padding: 5px 10px;
      margin: 0 0 5px 5px;
      border-radius: 5px;
    }
  }
}
